<template>
    <v-app>
      <NavbarHome />
      <v-main>

        <v-container>

          <v-row class="text-center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <p class="text-darley-h2  text-uppercase font-weight-bold font-color-darley mt-10">{{headerParagraphOne}}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <div class="d-flex flex-column mb-6" v-for="(image, index) in imageLayoutMainOne" :key="index">
                <v-hover v-slot="{ isHovering, props }" >
                  <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                    <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <div class="d-flex flex-column mb-6" v-for="(image, index) in imageLayoutMainTwo" :key="index">
                <v-hover v-slot="{ isHovering, props }" >
                  <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                    <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <div class="d-flex flex-column mb-6" v-for="(image, index) in imageLayoutMainThree" :key="index">
                <v-hover v-slot="{ isHovering, props }" >
                  <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                    <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <div class="d-flex flex-column mb-6" v-for="(image, index) in imageLayoutMainFour" :key="index">
                <v-hover v-slot="{ isHovering, props }" >
                  <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                    <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
          </v-row>

        </v-container>

        <v-dialog v-model="showDialog" color="black" fullscreen >
          <v-container fluid>
            <v-row class="mt-5 show-detail" justify="end">
              <v-col cols="auto">
                <v-icon v-if="fullscreen === false" class="text-white meeting-icon-align mr-5" @click="toggleFullscreen()" icon="mdi:mdi-fullscreen" ></v-icon>
                <v-icon v-else class="text-white meeting-icon-align mr-5" @click="toggleFullscreen()" icon="mdi:mdi-fullscreen-exit" ></v-icon>
                <!-- <v-icon v-if="isZoomed" class="text-white meeting-icon-align mr-5" @click="toggleZoom()" icon="mdi:mdi-magnify-minus-outline" ></v-icon>
                <v-icon v-else class="text-white meeting-icon-align mr-5" @click="toggleZoom()" icon="mdi:mdi-magnify-plus-outline" ></v-icon> -->
                <v-icon class="text-white meeting-icon-align mr-5" @click="closeDialog()" icon="mdi:mdi-close"></v-icon>
              </v-col>
            </v-row>
            <v-row v-if="isMobile" justify="center" align="center" no-gutters>              
                <v-img class="image-size-dialog" :src="selectedImage" width="300" cover>
                  <v-btn v-if="conditionImageMin" class="btn-left-position-dialog" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="30"></v-icon></v-btn>
                  <v-btn v-if="conditionImageMax" class="btn-right-position-dialog" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="30"></v-icon></v-btn>
                </v-img>
            </v-row>
            <v-row v-else-if="isTablet" justify="space-around" align="center">
              <v-col align-self="center" cols="auto">
                <v-btn v-if="conditionImageMin" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="30"></v-icon></v-btn>
              </v-col>
              <v-col cols="auto">
                <v-img :src="selectedImage" width="800"/>
              </v-col>
              <v-col align-self="center" cols="auto" >
                <v-btn v-if="conditionImageMax" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="30"></v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row v-else justify="space-around" align="center">
              <v-col align-self="center" cols="auto">
                <v-btn v-if="conditionImageMin && fullscreen === false" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="50"></v-icon></v-btn>
              </v-col>
              <v-col cols="auto">               
                <v-img ref="image" :src="selectedImage" alt="Image Dialog" :width="fullscreen ? 1960:1080"></v-img>               
              </v-col>
              <v-col align-self="center" cols="auto" >
                <v-btn v-if="conditionImageMax  && fullscreen === false" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="50"></v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-dialog>
        
      </v-main>
      <FooterHome />
    </v-app>
  </template>
  
  
  <script>
  import { mapGetters } from 'vuex';
  import { defineComponent } from "vue";
  
  import NavbarHome from "../components/home/NavbarHome.vue";
  import FooterHome from "../components/home/FooterHome.vue";
  
  export default defineComponent({
    name: "HomeView",
  
    components: {
      NavbarHome,
      FooterHome,
    },
    data: () => ({
      showDialog: false,
      selectedID: null,
      selectedImage: null,
      fullscreen: false,
      isZoomed: false,
      // headerParagraphOne: 'GALLERY',
      imageLayoutMainOne: [
          { id: 1, src: require("@/assets/images/DLH-28-scaled.jpg"), size:'300',},
          { id: 2, src: require("@/assets/images/DLH-33-scaled.jpg"), size:'300',},
          { id: 3, src: require("@/assets/images/DLH-24-scaled.jpg"), size:'800',},
          { id: 4, src: require("@/assets/images/DLH-5-scaled.jpg"), size:'300',},
          { id: 5, src: require("@/assets/images/DLH-88-scaled.jpg"), size:'300',},
          { id: 6, src: require("@/assets/images/DLH-26-scaled.jpg"), size:'800',},
          { id: 7, src: require("@/assets/images/DLH-94-scaled.jpg"), size:'800',},
          { id: 8, src: require("@/assets/images/DLH-76-scaled.jpg"), size:'800',},
          { id: 9, src: require("@/assets/images/DLH-25-scaled.jpg"), size:'300',},
          { id: 10, src: require("@/assets/images/DLH-72-scaled.jpg"), size:'300',},
        ],
      imageLayoutMainTwo: [
          { id: 11, src: require("@/assets/images/DLH-75-scaled.jpg"), size:'800',},
          { id: 12, src: require("@/assets/images/DLH-27-scaled.jpg"), size:'300',},
          { id: 13, src: require("@/assets/images/DLH-1-scaled.jpg"), size:'300',},
          { id: 14, src: require("@/assets/images/DLH-21-scaled.jpg"), size:'300',},
          { id: 15, src: require("@/assets/images/DLH-34-scaled.jpg"), size:'800',},
          { id: 16, src: require("@/assets/images/DLH-2-scaled.jpg"), size:'300',},
          { id: 17, src: require("@/assets/images/DLH-32-scaled.jpg"), size:'300',},
          { id: 18, src: require("@/assets/images/DLH-23-scaled.jpg"), size:'300',},
          { id: 19, src: require("@/assets/images/DLH-3-1-scaled.jpg"), size:'800',},
          { id: 20, src: require("@/assets/images/DLH-96-scaled.jpg"), size:'800',},

        ],
      imageLayoutMainThree: [
          { id: 21, src: require("@/assets/images/DLH-79-scaled.jpg"), size:'800',},
          { id: 22, src: require("@/assets/images/DLH-93-scaled.jpg"), size:'800',},
          { id: 23, src: require("@/assets/images/DLH-30-scaled.jpg"), size:'800',},
          { id: 24, src: require("@/assets/images/DLH-4-scaled.jpg"), size:'800',},
          { id: 25, src: require("@/assets/images/DLH-19-scaled.jpg"), size:'300',},
          { id: 26, src: require("@/assets/images/DLH-71-scaled.jpg"), size:'800',},
          { id: 27, src: require("@/assets/images/DLH-91-scaled.jpg"), size:'800',},
        ],
      imageLayoutMainFour: [
          { id: 28, src: require("@/assets/images/DLH-90-scaled.jpg"), size:'300',},
          { id: 29, src: require("@/assets/images/DLH-89-scaled.jpg"), size:'800',},
          { id: 30, src: require("@/assets/images/DLH-31-1-scaled.jpg"), size:'300',},
          { id: 31, src: require("@/assets/images/DLH-35-scaled.jpg"), size:'800',},
          { id: 32, src: require("@/assets/images/DLH-20-scaled.jpg"), size:'300',},
          { id: 33, src: require("@/assets/images/DLH-77-scaled.jpg"), size:'800',},
          { id: 34, src: require("@/assets/images/DLH-29-scaled.jpg"), size:'300',},
          { id: 35, src: require("@/assets/images/DLH-6-scaled.jpg"), size:'800',},
          { id: 36, src: require("@/assets/images/DLH-22-scaled.jpg"), size:'800',},
        ],
      imageAll: [
          { id: 1, src: require("@/assets/images/DLH-28-scaled.jpg"), size:'300',},
          { id: 2, src: require("@/assets/images/DLH-33-scaled.jpg"), size:'300',},
          { id: 3, src: require("@/assets/images/DLH-24-scaled.jpg"), size:'800',},
          { id: 4, src: require("@/assets/images/DLH-5-scaled.jpg"), size:'300',},
          { id: 5, src: require("@/assets/images/DLH-88-scaled.jpg"), size:'300',},
          { id: 6, src: require("@/assets/images/DLH-26-scaled.jpg"), size:'800',},
          { id: 7, src: require("@/assets/images/DLH-94-scaled.jpg"), size:'800',},
          { id: 8, src: require("@/assets/images/DLH-76-scaled.jpg"), size:'800',},
          { id: 9, src: require("@/assets/images/DLH-25-scaled.jpg"), size:'300',},
          { id: 10, src: require("@/assets/images/DLH-72-scaled.jpg"), size:'300',},
          { id: 11, src: require("@/assets/images/DLH-75-scaled.jpg"), size:'800',},
          { id: 12, src: require("@/assets/images/DLH-27-scaled.jpg"), size:'300',},
          { id: 13, src: require("@/assets/images/DLH-1-scaled.jpg"), size:'300',},
          { id: 14, src: require("@/assets/images/DLH-21-scaled.jpg"), size:'300',},
          { id: 15, src: require("@/assets/images/DLH-34-scaled.jpg"), size:'800',},
          { id: 16, src: require("@/assets/images/DLH-2-scaled.jpg"), size:'300',},
          { id: 17, src: require("@/assets/images/DLH-32-scaled.jpg"), size:'300',},
          { id: 18, src: require("@/assets/images/DLH-23-scaled.jpg"), size:'300',},
          { id: 19, src: require("@/assets/images/DLH-3-1-scaled.jpg"), size:'800',},
          { id: 20, src: require("@/assets/images/DLH-96-scaled.jpg"), size:'800',},
          { id: 21, src: require("@/assets/images/DLH-79-scaled.jpg"), size:'800',},
          { id: 22, src: require("@/assets/images/DLH-93-scaled.jpg"), size:'800',},
          { id: 23, src: require("@/assets/images/DLH-30-scaled.jpg"), size:'800',},
          { id: 24, src: require("@/assets/images/DLH-4-scaled.jpg"), size:'800',},
          { id: 25, src: require("@/assets/images/DLH-19-scaled.jpg"), size:'300',},
          { id: 26, src: require("@/assets/images/DLH-71-scaled.jpg"), size:'800',},
          { id: 27, src: require("@/assets/images/DLH-91-scaled.jpg"), size:'800',},
          { id: 28, src: require("@/assets/images/DLH-90-scaled.jpg"), size:'300',},
          { id: 29, src: require("@/assets/images/DLH-89-scaled.jpg"), size:'800',},
          { id: 30, src: require("@/assets/images/DLH-31-1-scaled.jpg"), size:'300',},
          { id: 31, src: require("@/assets/images/DLH-35-scaled.jpg"), size:'800',},
          { id: 32, src: require("@/assets/images/DLH-20-scaled.jpg"), size:'300',},
          { id: 33, src: require("@/assets/images/DLH-77-scaled.jpg"), size:'800',},
          { id: 34, src: require("@/assets/images/DLH-29-scaled.jpg"), size:'300',},
          { id: 35, src: require("@/assets/images/DLH-6-scaled.jpg"), size:'800',},
          { id: 36, src: require("@/assets/images/DLH-22-scaled.jpg"), size:'800',},
      ]
    }),
    computed:{
      ...mapGetters(['selectedLanguage']),

      headerParagraphOne() {
        const headerParagraphOne = {
          'Thai': 'แกลลอรี่',
          'English': 'Gallery',
          'China': '画廊',
          'Korean': '갱도',
          'Japan': 'ギャラリー',
        };
        return headerParagraphOne[this.selectedLanguage] || headerParagraphOne['English'];
      },

      isMobile() {
        const display = this.$vuetify.display
        if (display.xs) {
          return true
        }
        else{
          return false
        }
      },
      isTablet() {
        const display = this.$vuetify.display
        if (display.sm || display.md) {
          return true
        }
        else{
          return false
        }
      },
      conditionImageMin() {
        const minIdObject = this.imageAll.reduce((minObj, obj) => {return obj.id < minObj.id ? obj : minObj;}, this.imageAll[0]);
        if(minIdObject.id < this.selectedID) {
          return true
        }
        else{
          return false
        }

      },
      conditionImageMax() {
        const maxIdObject = this.imageAll.reduce((maxObj, obj) => {return obj.id > maxObj.id ? obj : maxObj;}, this.imageAll[0]);
        if(maxIdObject.id > this.selectedID) {
          return true
        }
        else{
          return false
        }
      }
    },

    methods: {
      openDialog(image) {
        this.selectedImage = image.src;
        this.selectedID = image.id;
        this.showDialog = true;
      },
      prevImage() {
        const filteredImage = this.imageAll.filter(obj => obj.id == this.selectedID - 1)[0]
        this.selectedImage = filteredImage.src;
        this.selectedID = filteredImage.id;
      },
      nextImage() {
        const filteredImage = this.imageAll.filter(obj => obj.id == this.selectedID + 1)[0]
        this.selectedImage = filteredImage.src;
        this.selectedID = filteredImage.id;
      },
      toggleFullscreen() {
        const docElement = document.documentElement;
        if (document.fullscreenElement) {
          this.fullscreen = !this.fullscreen;
          document.exitFullscreen();
        } else {
          this.fullscreen = !this.fullscreen;
          docElement.requestFullscreen()
          .catch(error => {
              console.error('Error attempting to enable fullscreen:', error);
            });
        }
      },
      toggleZoom() {
        this.isZoomed = !this.isZoomed;
      },

      
      closeDialog(){
        this.showDialog = false;
        this.selectedImage = null;
        this.selectedID = null;
        if (this.fullscreen === true) {
          this.fullscreen = false;
          document.exitFullscreen();
        }
      }
    },
  });
  </script>
  