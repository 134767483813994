<template>
    <v-app>
      <NavbarHome />
      <v-main>
        <v-container>

          <v-row class="text-center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <p class="text-darley-h2  text-uppercase font-weight-bold font-color-darley">{{headerParagraphOne}}</p>
            </v-col>
          </v-row>

          <v-row>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-row>
                  <template v-for="(image, index) in imageLayoutMainOne" :key="index">                    
                    <v-col cols="12" xs="12" :sm="image.cols" :md="image.cols" :lg="image.cols" :xl="image.cols">
                      <v-hover v-slot="{ isHovering, props }">
                        <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                          <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                        </v-card>
                      </v-hover>
                    </v-col>  
  
                    <v-col v-if="image.children" cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="d-flex flex-column">
                      <v-row>
                        <v-col v-for="(children,childIdx) in image.children" :key="childIdx" cols="12" xs="12" :sm="children.cols" :md="children.cols" :lg="children.cols" :xl="children.cols">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                              <v-img :src=children.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" height="100%" cover></v-img>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                </v-row>
              </v-col>

              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <v-row>
                  <v-col v-for="(image, i) in imageLayoutRight" :key="i" cols="12" xs="12" :sm="image.cols" :md="image.cols" :lg="image.cols" :xl="image.cols">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-card :elevation="isHovering ? 10 : 1" :class="{ 'on-hover': isHovering }" v-bind="props" @click="openDialog(image)">
                        <v-img :src=image.src :gradient=" isHovering ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)' : undefined" ></v-img>
                      </v-card>
                    </v-hover>                    
                  </v-col>
                </v-row>
              </v-col>
          </v-row>

          <v-row class="text-center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-btn variant="tonal" class="text-decoration-underline btn-meeting-booking" size="x-large" href="https://darleyhotel.co.th/gallery-2/#" rounded>{{ titleBook }}</v-btn>
            </v-col>
          </v-row>

        </v-container>

        <v-dialog v-model="showDialog" color="black" fullscreen >
          <v-container fluid>
            <v-row class="mt-5 show-detail" justify="end">
              <v-col cols="auto">
                <v-icon v-if="fullscreen === false" class="text-white meeting-icon-align mr-5" @click="toggleFullscreen()" icon="mdi:mdi-fullscreen" ></v-icon>
                <v-icon v-else class="text-white meeting-icon-align mr-5" @click="toggleFullscreen()" icon="mdi:mdi-fullscreen-exit" ></v-icon>
                <!-- <v-icon v-if="isZoomed" class="text-white meeting-icon-align mr-5" @click="toggleZoom()" icon="mdi:mdi-magnify-minus-outline" ></v-icon>
                <v-icon v-else class="text-white meeting-icon-align mr-5" @click="toggleZoom()" icon="mdi:mdi-magnify-plus-outline" ></v-icon> -->
                <v-icon class="text-white meeting-icon-align mr-5" @click="closeDialog()" icon="mdi:mdi-close"></v-icon>
              </v-col>
            </v-row>
            <v-row v-if="isMobile" justify="center" align="center" no-gutters>              
                <v-img class="image-size-dialog" :src="selectedImage" width="300" cover>
                  <v-btn v-if="conditionImageMin" class="btn-left-position-dialog" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="30"></v-icon></v-btn>
                  <v-btn v-if="conditionImageMax" class="btn-right-position-dialog" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="30"></v-icon></v-btn>
                </v-img>
            </v-row>
            <v-row v-else-if="isTablet" justify="space-around" align="center">
              <v-col align-self="center" cols="auto">
                <v-btn v-if="conditionImageMin" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="30"></v-icon></v-btn>
              </v-col>
              <v-col cols="auto">
                <v-img :src="selectedImage" width="800"/>
              </v-col>
              <v-col align-self="center" cols="auto" >
                <v-btn v-if="conditionImageMax" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="30"></v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row v-else justify="space-around" align="center">
              <v-col align-self="center" cols="auto">
                <v-btn v-if="conditionImageMin && fullscreen === false" color="white" variant="plain" @click="prevImage"><v-icon color="white" icon="mdi:mdi-chevron-left" size="50"></v-icon></v-btn>
              </v-col>
              <v-col cols="auto">               
                <v-img ref="image" :src="selectedImage" alt="Image Dialog" :width="fullscreen ? 1960:1080"></v-img>               
              </v-col>
              <v-col align-self="center" cols="auto" >
                <v-btn v-if="conditionImageMax  && fullscreen === false" color="white" variant="plain" @click="nextImage"><v-icon color="white" icon="mdi:mdi-chevron-right" size="50"></v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-dialog>
        
      </v-main>
      <FooterHome />
    </v-app>
  </template>
  
<script>
  import { mapGetters } from 'vuex';

  import { defineComponent } from "vue";
  
  import NavbarHome from "../components/home/NavbarHome.vue";
  import FooterHome from "../components/home/FooterHome.vue";
  
  export default defineComponent({
    name: "MeetingView",
  
    components: {
      NavbarHome,
      FooterHome,
    },
    data: () => ({
      showDialog: false,
      selectedID: null,
      selectedImage: null,
      fullscreen: false,
      isZoomed: false,
      // headerParagraphOne: 'MEETING ROOM',
      imageLayoutMainOne: [
          { id: 1, src: require("@/assets/images/DLH-50-scaled.jpg"), cols: 4, size:'150',},
          { id: 2,src: require("@/assets/images/DLH-47-scaled.jpg"), cols: 4, size:'150',},
          { id: 3,src: require("@/assets/images/DLH-53-scaled.jpg"), cols: 4, size:'150',},
          { id: 4,src: require("@/assets/images/DLH-52-scaled.jpg"), cols: 4, size:'150',},
          { id: 5,src: require("@/assets/images/DLH-51-scaled.jpg"), cols: 4, size:'150',},
          { id: 6,src: require("@/assets/images/DLH-48-scaled.jpg"), cols: 4, size:'150',},
          { id: 7,src: require("@/assets/images/DLH-49-scaled.jpg"), cols: 4, size:'400',
            children: [
              { id: 8,src: require("@/assets/images/DLH-55-scaled.jpg"), cols: 6, size:'150'}, 
              { id: 9,src: require("@/assets/images/DLH-54-scaled.jpg"), cols: 6, size:'150'}, 
              { id: 10,src: require("@/assets/images/DLH-44-scaled.jpg"), cols: 6, size:'150'}, 
            ],
          },
        ],
      imageLayoutRight: [
          { id: 11,src: require("@/assets/images/DLH-46-scaled.jpg"), cols: 12, size:'400',},
          { id: 12,src: require("@/assets/images/DLH-45-scaled.jpg"), cols: 12, size:'400',},
        ],
      imageAll: [
          { id: 1,src: require("@/assets/images/DLH-50-scaled.jpg"),},
          { id: 2,src: require("@/assets/images/DLH-47-scaled.jpg"),},
          { id: 3,src: require("@/assets/images/DLH-53-scaled.jpg"),},
          { id: 4,src: require("@/assets/images/DLH-52-scaled.jpg"),},
          { id: 5,src: require("@/assets/images/DLH-51-scaled.jpg"),},
          { id: 6,src: require("@/assets/images/DLH-48-scaled.jpg"),},
          { id: 7,src: require("@/assets/images/DLH-49-scaled.jpg"),},
          { id: 8,src: require("@/assets/images/DLH-55-scaled.jpg"),}, 
          { id: 9,src: require("@/assets/images/DLH-54-scaled.jpg"),}, 
          { id: 10,src: require("@/assets/images/DLH-44-scaled.jpg"),},
          { id: 11,src: require("@/assets/images/DLH-46-scaled.jpg"),},
          { id: 12,src: require("@/assets/images/DLH-45-scaled.jpg"),}, 
      ]
    }),

    computed:{
      ...mapGetters(['selectedLanguage']),

      titleBook() {
        const bookMap = {
          'Thai': 'จองห้องพัก',
          'English': 'Book Now',
          'China': '写在这里',
          'Korean': '객실 예약',
          'Japan': '今すぐ予約する',
        };
        return bookMap[this.selectedLanguage] || bookMap['English'];
      },

      headerParagraphOne() {
        const headerParagraphOne = {
          'Thai': 'ห้องประชุม',
          'English': 'Meeting Room',
          'China': '会议室',
          'Korean': '회의실',
          'Japan': '会議室',
        };
        return headerParagraphOne[this.selectedLanguage] || headerParagraphOne['English'];
      },

      isMobile() {
        const display = this.$vuetify.display
        if (display.xs) {
          return true
        }
        else{
          return false
        }
      },
      isTablet() {
        const display = this.$vuetify.display
        if (display.sm || display.md) {
          return true
        }
        else{
          return false
        }
      },
      conditionImageMin() {
        const minIdObject = this.imageAll.reduce((minObj, obj) => {return obj.id < minObj.id ? obj : minObj;}, this.imageAll[0]);
        if(minIdObject.id < this.selectedID) {
          return true
        }
        else{
          return false
        }

      },
      conditionImageMax() {
        const maxIdObject = this.imageAll.reduce((maxObj, obj) => {return obj.id > maxObj.id ? obj : maxObj;}, this.imageAll[0]);
        if(maxIdObject.id > this.selectedID) {
          return true
        }
        else{
          return false
        }
      }
    },

    methods: {
      openDialog(image) {
        this.selectedImage = image.src;
        this.selectedID = image.id;
        this.showDialog = true;
      },
      prevImage() {
        const filteredImage = this.imageAll.filter(obj => obj.id == this.selectedID - 1)[0]
        this.selectedImage = filteredImage.src;
        this.selectedID = filteredImage.id;
      },
      nextImage() {
        const filteredImage = this.imageAll.filter(obj => obj.id == this.selectedID + 1)[0]
        this.selectedImage = filteredImage.src;
        this.selectedID = filteredImage.id;
      },
      toggleFullscreen() {
        const docElement = document.documentElement;
        if (document.fullscreenElement) {
          this.fullscreen = !this.fullscreen;
          document.exitFullscreen();
        } else {
          this.fullscreen = !this.fullscreen;
          docElement.requestFullscreen()
          .catch(error => {
              console.error('Error attempting to enable fullscreen:', error);
            });
        }
      },
      toggleZoom() {
        this.isZoomed = !this.isZoomed;
      },

      
      closeDialog(){
        this.showDialog = false;
        this.selectedImage = null;
        this.selectedID = null;
        if (this.fullscreen === true) {
          this.fullscreen = false;
          document.exitFullscreen();
        }
      }
    },
  });
</script>
  
<style lang="sass" scoped>
.v-card.on-hover.v-theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>