<template>

  <v-app-bar app color="#033436" height="80">
    <v-toolbar-title><img class="mt-5" :src="navigationLogo" height="80"/></v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="isMobile == false" class="mr-5">
      <router-link v-for="(item, i) in navigationLinks" :key="i" :to=item.link class="not-active-link" exact-active-class="active-link">
        <v-btn variant="plain" size="x-large">{{ item.text }}</v-btn>
      </router-link>
      <v-btn class="mt-n5" size="x-large" @click="dialog = true">
        <v-img :src="titleImage" height="24" width="24"></v-img>
      </v-btn>
      <v-btn class="ml-16 mt-n5" variant="tonal" color="white nav-btn-color" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
    </div>
    <v-menu v-model="showDropdown">
      <template v-slot:activator="{ props }">
        <v-btn icon v-if="isMobile" @click="showDropdown = !showDropdown" v-bind="props">
          <v-icon class="text-white" icon="mdi:mdi-menu"></v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in navigationLinks" :key="i" class="text-center">
          <router-link :to=item.link class="not-active-link-menu" exact-active-class="active-link">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </router-link>
        </v-list-item>
        <v-list-item class="text-center">
          <v-btn size="x-large" @click="dialog = true" rounded>
            <v-img :src="titleImage" height="24" width="24"></v-img>
          </v-btn>
        </v-list-item>
        <v-list-item >
          <v-btn variant="tonal" color="white nav-btn-color" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-list>
        <v-list-subheader class="text-center mx-auto my-0">Languages</v-list-subheader>

        <v-list-item v-for="(item, i) in items" :key="i" :value="item" class="justify-center" color="#033436" rounded="xl" @click="updateLanguage(item)">
          <template v-slot:prepend>
            <v-img :src=item.src height="auto" width="50"></v-img>
          </template>

          <v-list-item-title class="ml-5"> {{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({     
    dialog: false, 
    showDropdown: false,
    location: 'bottom',
    navigationLogo: require("@/assets/images/logo_navbar.png"),
    items: [
          { text: 'Thai', src: require("@/assets/images/flags/thai.png") },
          { text: 'English', src: require("@/assets/images/flags/english.png") },
          { text: 'China', src: require("@/assets/images/flags/china.png") },
          { text: 'Korean', src: require("@/assets/images/flags/korean.png") },
          { text: 'Japan', src: require("@/assets/images/flags/japan.png") },
        ],
    // selectedLanguage: 'Thai',
  }),

  computed: {
    ...mapGetters(['selectedLanguage']),

    isMobile() {
      const display = this.$vuetify.display
      if (display.xs || display.sm) {
        return true
      }
      else{
        return false
      }
    },
    titleImage() {
      const imageMap = {
        'Thai': require("@/assets/images/flags/thai.png"),
        'English': require("@/assets/images/flags/english.png"),
        'China': require("@/assets/images/flags/china.png"),
        'Korean': require("@/assets/images/flags/korean.png"),
        'Japan': require("@/assets/images/flags/japan.png"),
      };
      return imageMap[this.selectedLanguage] || imageMap['English'];
    },
    titleBook() {
      const bookMap = {
        'Thai': 'จองห้องพัก',
        'English': 'Book Now',
        'China': '写在这里',
        'Korean': '객실 예약',
        'Japan': '今すぐ予約する',
      };
      return bookMap[this.selectedLanguage] || bookMap['English'];
    },
    navigationLinks() {
      const linkMap = {
        'Thai': [
          { text: 'หน้าแรก', link: '/' },
          { text: 'ห้องพัก', link: '/rooms' },
          { text: 'ห้องประชุม', link: '/meeting' },
          { text: 'แกลลอรี่', link: '/gallery' },
        ],
        'English': [
          { text: 'Home', link: '/' },
          { text: 'Room', link: '/rooms' },
          { text: 'Meeting Room', link: '/meeting' },
          { text: 'Gallery', link: '/gallery' },
        ],
        'China': [
          { text: '第一页', link: '/' },
          { text: '房间', link: '/rooms' },
          { text: '会议室', link: '/meeting' },
          { text: '画廊', link: '/gallery' },
        ],
        'Korean': [
          { text: '홈페이지', link: '/' },
          { text: '방', link: '/rooms' },
          { text: '회의실', link: '/meeting' },
          { text: '갱도', link: '/gallery' },
        ],
        'Japan': [
          { text: 'ホームページ', link: '/' },
          { text: '部屋', link: '/rooms' },
          { text: '会議室', link: '/meeting' },
          { text: 'ギャラリー', link: '/gallery' },
        ],
      };
      return linkMap[this.selectedLanguage] || linkMap['English'];
    },
  },

  methods: {
    ...mapActions(['setLanguage']),
    
    handleWindowResize() {
      if (!this.isMobile) {
        this.showDropdown = false;
      }
    },
    updateLanguage(item) {
      this.setLanguage(item.text);
      this.dialog = false;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },
};
</script>