<template>
    <v-app>
      <NavbarHome />
      <v-main>
        <v-container>

          <v-row class="text-center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <p class="text-darley-h2  text-uppercase font-weight-bold font-color-darley">{{headerParagraphOne}}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-carousel show-arrows="hover" height="400" cycle>
                <v-carousel-item v-for="(item, i) in carouselItemsOne" :key="i" href="https://bit.ly/darley1215" :src="item.src" gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.3)" cover>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col class="text-center" cols="12"> 
                      <p class="text-image-darley-h6 text-white font-weight-bold text-decoration-underline">{{item.text}}</p>
                      <v-btn class="text-white mt-5" variant="outlined" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="mt-5" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <p class="text-room-darley-h6" v-for="(item, i) in titleParagraphOne" :key="i"><v-icon class="mr-3" icon="fas fa-dot-circle" color="#f4939c" size="15"></v-icon>{{ item }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-carousel show-arrows="hover" height="400" cycle>
                <v-carousel-item v-for="(item, i) in carouselItemsTwo" :key="i" :src="item.src" gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.3)" cover>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col class="text-center" cols="12" >
                      <p class="text-image-darley-h6 text-white font-weight-bold text-decoration-underline">{{item.text}}</p>
                      <v-btn class="text-white mt-5" variant="outlined" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="mt-5" xs="12" cols="12" sm="4" md="4" lg="4" xl="4">
              <p class="text-room-darley-h6" v-for="(item, i) in titleParagraphTwo" :key="i"><v-icon class="mr-3" icon="fas fa-dot-circle" color="#f4939c" size="15"></v-icon>{{ item }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-carousel show-arrows="hover" height="400" cycle>
                <v-carousel-item v-for="(item, i) in carouselItemsThree" :key="i" :src="item.src" gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.3)" cover>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col class="text-center" cols="12" >
                      <p class="text-image-darley-h6 text-white font-weight-bold text-decoration-underline">{{item.text}}</p>
                      <v-btn class="text-white mt-5" variant="outlined" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="mt-5" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <p class="text-room-darley-h6" v-for="(item, i) in titleParagraphThree" :key="i"><v-icon class="mr-3" icon="fas fa-dot-circle" color="#f4939c" size="15"></v-icon>{{ item }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-carousel show-arrows="hover" height="400" cycle>
                <v-carousel-item v-for="(item, i) in carouselItemsFour" :key="i" :src="item.src" gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.3)" cover>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col class="text-center" cols="12" >
                      <p class="text-image-darley-h6 text-white font-weight-bold text-decoration-underline">{{item.text}}</p>
                      <v-btn class="text-white mt-5" variant="outlined" size="large" href="https://bit.ly/darley1215" rounded>{{ titleBook }}</v-btn>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="mt-2" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <p class="text-room-darley-h6" v-for="(item, i) in titleParagraphFour" :key="i"><v-icon class="mr-3" icon="fas fa-dot-circle" color="#f4939c" size="15"></v-icon>{{ item }}</p>
            </v-col>
          </v-row>

        </v-container>  
      </v-main>
      <FooterHome />
    </v-app>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';

  import { defineComponent } from "vue";
  
  import NavbarHome from "../components/home/NavbarHome.vue";
  import FooterHome from "../components/home/FooterHome.vue";
  
  export default defineComponent({
    name: "HomeView",
  
    components: {
      NavbarHome,
      FooterHome,
    },
    data: () => ({

    }),
    computed: {
      ...mapGetters(['selectedLanguage']),

      headerParagraphOne() {
        const headerParagraphOne = {
          'Thai': 'Rooms',
          'English': 'Rooms',
          'China': '房間',
          'Korean': '객실',
          'Japan': '部屋',
        };
        return headerParagraphOne[this.selectedLanguage] || headerParagraphOne['English'];
      },
      carouselItemsOne() {
        const carouselItemsOne = {
          'Thai': [
                    {src: require("@/assets/images/DLH-38-scaled.jpg"), text: 'Superior Room'},
                    {src: require("@/assets/images/DLH-37-scaled.jpg"), text: 'Superior Room'},
                    {src:require("@/assets/images/DLH-36-scaled.jpg"), text: 'Superior Room'},
                    {src:require("@/assets/images/DLH-32-scaled.jpg"), text: 'Superior Room'},
                    {src:require("@/assets/images/DLH-33-scaled.jpg"), text: 'Superior Room'},
                    {src:require("@/assets/images/DLH-34-scaled.jpg"), text: 'Superior Room'},
                  ],
          'English': [
                      {src: require("@/assets/images/DLH-38-scaled.jpg"), text: 'Superior Room'},
                      {src: require("@/assets/images/DLH-37-scaled.jpg"), text: 'Superior Room'},
                      {src:require("@/assets/images/DLH-36-scaled.jpg"), text: 'Superior Room'},
                      {src:require("@/assets/images/DLH-32-scaled.jpg"), text: 'Superior Room'},
                      {src:require("@/assets/images/DLH-33-scaled.jpg"), text: 'Superior Room'},
                      {src:require("@/assets/images/DLH-34-scaled.jpg"), text: 'Superior Room'},
                    ],
          'China': [
                      {src: require("@/assets/images/DLH-38-scaled.jpg"), text: '高级客房'},
                      {src: require("@/assets/images/DLH-37-scaled.jpg"), text: '高级客房'},
                      {src:require("@/assets/images/DLH-36-scaled.jpg"), text: '高级客房'},
                      {src:require("@/assets/images/DLH-32-scaled.jpg"), text: '高级客房'},
                      {src:require("@/assets/images/DLH-33-scaled.jpg"), text: '高级客房'},
                      {src:require("@/assets/images/DLH-34-scaled.jpg"), text: '高级客房'},
                    ],
          'Korean': [
                    {src: require("@/assets/images/DLH-38-scaled.jpg"), text: '슈페리어룸'},
                    {src: require("@/assets/images/DLH-37-scaled.jpg"), text: '슈페리어룸'},
                    {src:require("@/assets/images/DLH-36-scaled.jpg"), text: '슈페리어룸'},
                    {src:require("@/assets/images/DLH-32-scaled.jpg"), text: '슈페리어룸'},
                    {src:require("@/assets/images/DLH-33-scaled.jpg"), text: '슈페리어룸'},
                    {src:require("@/assets/images/DLH-34-scaled.jpg"), text: '슈페리어룸'},
                  ],
          'Japan': [
                    {src: require("@/assets/images/DLH-38-scaled.jpg"), text: 'スーペリアルーム'},
                    {src: require("@/assets/images/DLH-37-scaled.jpg"), text: 'スーペリアルーム'},
                    {src:require("@/assets/images/DLH-36-scaled.jpg"), text: 'スーペリアルーム'},
                    {src:require("@/assets/images/DLH-32-scaled.jpg"), text: 'スーペリアルーム'},
                    {src:require("@/assets/images/DLH-33-scaled.jpg"), text: 'スーペリアルーム'},
                    {src:require("@/assets/images/DLH-34-scaled.jpg"), text: 'スーペリアルーム'},
                  ],
        };
        return carouselItemsOne[this.selectedLanguage] || carouselItemsOne['English'];
      },
      carouselItemsTwo() {
        const carouselItemsTwo = {
          'Thai': [
                  {src: require("@/assets/images/DLH-3-1-scaled.jpg"), text: 'Premier Room'},
                ],
          'English': [
                      {src: require("@/assets/images/DLH-3-1-scaled.jpg"), text: 'Premier Room'},
                    ],
          'China': [
                      {src: require("@/assets/images/DLH-3-1-scaled.jpg"), text: '豪华房'},
                    ],
          'Korean': [
                      {src: require("@/assets/images/DLH-3-1-scaled.jpg"), text: '프리미어룸'},
                    ],
          'Japan': [
                      {src: require("@/assets/images/DLH-3-1-scaled.jpg"), text: 'プレミアルーム'},
                    ],
        };
        return carouselItemsTwo[this.selectedLanguage] || carouselItemsTwo['English'];
      },
      carouselItemsThree() {
        const carouselItemsThree = {
          'Thai': [
                      {src: require("@/assets/images/DLH-31-scaled.jpg"), text: 'Deluxe Double Room'},
                    ],
          'English': [
                      {src: require("@/assets/images/DLH-31-scaled.jpg"), text: 'Deluxe Double Room'},
                    ],
          'China':[
                    {src: require("@/assets/images/DLH-31-scaled.jpg"), text: '豪华双人间'},
                  ],
          'Korean': [
                      {src: require("@/assets/images/DLH-31-scaled.jpg"), text: '디럭스 더블룸'},
                    ],
          'Japan': [
                    {src: require("@/assets/images/DLH-31-scaled.jpg"), text: 'デラックス ダブルルーム'},
                  ],
        };
        return carouselItemsThree[this.selectedLanguage] || carouselItemsThree['English'];
      },
      carouselItemsFour() {
        const carouselItemsFour = {
          'Thai': [
                    {src: require("@/assets/images/DLH-21-scaled.jpg"), text: 'Deluxe Double Single Room'},
                  ],
          'English': [
                        {src: require("@/assets/images/DLH-21-scaled.jpg"), text: 'Deluxe Double Single Room'},
                      ],
          'China': [
                      {src: require("@/assets/images/DLH-21-scaled.jpg"), text: '豪华双人单人间'},
                    ],
          'Korean': [
                      {src: require("@/assets/images/DLH-21-scaled.jpg"), text: '디럭스 더블 싱글 룸'},
                    ],
          'Japan': [
                      {src: require("@/assets/images/DLH-21-scaled.jpg"), text: 'デラックス ダブル シングル ルーム'},
                    ],
        };
        return carouselItemsFour[this.selectedLanguage] || carouselItemsFour['English'];
      },
      titleBook() {
        const bookMap = {
          'Thai': 'จองห้องพัก',
          'English': 'Book Now',
          'China': '写在这里',
          'Korean': '객실 예약',
          'Japan': '今すぐ予約する',
        };
        return bookMap[this.selectedLanguage] || bookMap['English'];
      },
      titleParagraphOne() {
        const titleParagraphOne = {
          'Thai': [
                    'ทีวีจอแอลซีดี 32”',
                    'เครื่องดื่มชาและกาแฟ',
                    'ตู้เซฟ',
                    'เครื่องปรับอากาศ',
                    'บริการฟรีไวไฟ',
                    'ช่องเคเบิลทีวี',
                    'ระบบคีย์การ์ด เข้า – ออก',
                    'ฝักบัวน้ำอุ่นและน้ำเย็น',
                    'ตู้เสื้อผ้า',
                    'ตู้เย็น',
                    'สิ่งอำนวยความสะดวกต่างๆ',
                  ],
          'English': [
                    'LCD TV 32”',
                    'Coffee and Tea Set',
                    'Safe Deposit Box',
                    'Individually Controlled air-conditioning',
                    'Free WIFI',
                    'Cable TV with satellite channels',
                    'Key card access',
                    'Hot & Cold Shower',
                    'Wardrobe',
                    'Refrigerator',
                    'Well-appointed bathroom with amenities',
                  ],
          'China': [
                    '液晶电视 32 英寸',
                    '咖啡和茶具',
                    '保险箱',
                    '独立控制空调',
                    '免费无线网络服务',
                    '有线电视频道',
                    '钥匙卡访问',
                    '冷热水淋浴',
                    '衣柜',
                    '冰箱',
                    '各种设施',
                  ],
          'Korean': [
                    'LCD TV 32인치',
                    '차와 커피 음료',
                    '안전한',
                    '에어컨',
                    '무료 Wi-Fi 서비스',
                    '케이블 TV 채널',
                    '키 카드 이용',
                    '냉온수 샤워',
                    '옷장',
                    '냉장고',
                    '다양한 시설',
                  ],
          'Japan': [
                    '液晶テレビ 32 インチ',
                    'コーヒーと紅茶のセット',
                    '貸金庫',
                    'エアコン',
                    '無料Wi-Fi',
                    'ケーブルテレビのチャンネル',
                    'キーカードアクセス',
                    '温冷シャワー',
                    'ワードローブ',
                    '冷蔵庫',
                    '各種施設',
                  ],
        };
        return titleParagraphOne[this.selectedLanguage] || titleParagraphOne['English'];
      },
      titleParagraphTwo() {
        const titleParagraphTwo = {
          'Thai': [
                    'ทีวีจอแอลซีดี 32”',
                    'เครื่องดื่มชาและกาแฟ',
                    'ตู้เซฟ',
                    'เครื่องปรับอากาศ',
                    'บริการฟรีไวไฟ',
                    'ช่องเคเบิลทีวี',
                    'ระบบคีย์การ์ด เข้า – ออก',
                    'ฝักบัวน้ำอุ่นและน้ำเย็น',
                    'ตู้เสื้อผ้า',
                    'ตู้เย็น',
                    'สิ่งอำนวยความสะดวกต่างๆ',
                  ],
          'English': [
                    'LCD TV 32”',
                    'Coffee and Tea Set',
                    'Safe Deposit Box',
                    'Individually Controlled air-conditioning',
                    'Free WIFI',
                    'Cable TV with satellite channels',
                    'Key card access',
                    'Hot & Cold Shower',
                    'Wardrobe',
                    'Refrigerator',
                    'Well-appointed bathroom with amenities',
                  ],
          'China': [
                    '液晶电视 32 英寸',
                    '咖啡和茶具',
                    '保险箱',
                    '独立控制空调',
                    '免费无线网络服务',
                    '有线电视频道',
                    '钥匙卡访问',
                    '冷热水淋浴',
                    '衣柜',
                    '冰箱',
                    '各种设施',
                  ],
          'Korean': [
                    'LCD TV 32인치',
                    '차와 커피 음료',
                    '안전한',
                    '에어컨',
                    '무료 Wi-Fi 서비스',
                    '케이블 TV 채널',
                    '키 카드 이용',
                    '냉온수 샤워',
                    '옷장',
                    '냉장고',
                    '다양한 시설',
                  ],
          'Japan': [
                    '液晶テレビ 32 インチ',
                    'コーヒーと紅茶のセット',
                    '貸金庫',
                    'エアコン',
                    '無料Wi-Fi',
                    'ケーブルテレビのチャンネル',
                    'キーカードアクセス',
                    '温冷シャワー',
                    'ワードローブ',
                    '冷蔵庫',
                    '各種施設',
                  ],
        };
        return titleParagraphTwo[this.selectedLanguage] || titleParagraphTwo['English'];
      },
      titleParagraphThree() {
        const titleParagraphThree = {
          'Thai': [
                    'ทีวีจอแอลซีดี 32”',
                    'เครื่องดื่มชาและกาแฟ',
                    'ตู้เซฟ',
                    'เครื่องปรับอากาศ',
                    'บริการฟรีไวไฟ',
                    'ช่องเคเบิลทีวี',
                    'ระบบคีย์การ์ด เข้า – ออก',
                    'ฝักบัวน้ำอุ่นและน้ำเย็น',
                    'ตู้เสื้อผ้า',
                    'ตู้เย็น',
                    'สิ่งอำนวยความสะดวกต่างๆ',
                  ],
          'English': [
                    'LCD TV 32”',
                    'Coffee and Tea Set',
                    'Safe Deposit Box',
                    'Individually Controlled air-conditioning',
                    'Free WIFI',
                    'Cable TV with satellite channels',
                    'Key card access',
                    'Hot & Cold Shower',
                    'Wardrobe',
                    'Refrigerator',
                    'Well-appointed bathroom with amenities',
                  ],
          'China': [
                    '液晶电视 32 英寸',
                    '咖啡和茶具',
                    '保险箱',
                    '独立控制空调',
                    '免费无线网络服务',
                    '有线电视频道',
                    '钥匙卡访问',
                    '冷热水淋浴',
                    '衣柜',
                    '冰箱',
                    '各种设施',
                  ],
          'Korean': [
                    'LCD TV 32인치',
                    '차와 커피 음료',
                    '안전한',
                    '에어컨',
                    '무료 Wi-Fi 서비스',
                    '케이블 TV 채널',
                    '키 카드 이용',
                    '냉온수 샤워',
                    '옷장',
                    '냉장고',
                    '다양한 시설',
                  ],
          'Japan': [
                    '液晶テレビ 32 インチ',
                    'コーヒーと紅茶のセット',
                    '貸金庫',
                    'エアコン',
                    '無料Wi-Fi',
                    'ケーブルテレビのチャンネル',
                    'キーカードアクセス',
                    '温冷シャワー',
                    'ワードローブ',
                    '冷蔵庫',
                    '各種施設',
                  ],
        };
        return titleParagraphThree[this.selectedLanguage] || titleParagraphThree['English'];
      },
      titleParagraphFour() {
        const titleParagraphFour = {
          'Thai': [
                    'ทีวีจอแอลซีดี 32”',
                    'เครื่องดื่มชาและกาแฟ',
                    'ตู้เซฟ',
                    'เครื่องปรับอากาศ',
                    'บริการฟรีไวไฟ',
                    'ช่องเคเบิลทีวี',
                    'ระบบคีย์การ์ด เข้า – ออก',
                    'ฝักบัวน้ำอุ่นและน้ำเย็น',
                    'ตู้เสื้อผ้า',
                    'ตู้เย็น',
                    'สิ่งอำนวยความสะดวกต่างๆ',
                    'เก้าอี้โซฟา',
                  ],
          'English': [
                    'LCD TV 32”',
                    'Coffee and Tea Set',
                    'Safe Deposit Box',
                    'Individually Controlled air-conditioning',
                    'Free WIFI',
                    'Cable TV with satellite channels',
                    'Key card access',
                    'Hot & Cold Shower',
                    'Wardrobe',
                    'Refrigerator',
                    'Well-appointed bathroom with amenities',
                    'Sofa',
                  ],
          'China': [
                    '液晶电视 32 英寸',
                    '咖啡和茶具',
                    '保险箱',
                    '独立控制空调',
                    '免费无线网络服务',
                    '有线电视频道',
                    '钥匙卡访问',
                    '冷热水淋浴',
                    '衣柜',
                    '冰箱',
                    '各种设施',
                    '沙发椅',
                  ],
          'Korean': [
                    'LCD TV 32인치',
                    '차와 커피 음료',
                    '안전한',
                    '에어컨',
                    '무료 Wi-Fi 서비스',
                    '케이블 TV 채널',
                    '키 카드 이용',
                    '냉온수 샤워',
                    '옷장',
                    '냉장고',
                    '다양한 시설',
                    '소파 의자',
                  ],
          'Japan': [
                    '液晶テレビ 32 インチ',
                    'コーヒーと紅茶のセット',
                    '貸金庫',
                    'エアコン',
                    '無料Wi-Fi',
                    'ケーブルテレビのチャンネル',
                    'キーカードアクセス',
                    '温冷シャワー',
                    'ワードローブ',
                    '冷蔵庫',
                    '各種施設',
                    'ソファーチェア',
                  ],
        };
        return titleParagraphFour[this.selectedLanguage] || titleParagraphFour['English'];
      },

    }
  });
  </script>
  